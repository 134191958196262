@import url("https://fonts.googleapis.com/css2?family=Varela&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vazirmatn:wght@200;300;400;500&display=swap");
body {
  font-family: "Varela", sans-serif;
  overflow-x: hidden;
}
html {
  overflow-x: hidden;
}
a {
  text-decoration: none !important;
}
.row {
  width: 100%;
}
.dis-hide-show {
  display: none;
}
.hr {
  width: 100%;
  border-color: #d3d3d3;
  margin-top: 2px;
  float: left;
  top: 40px;
  position: relative;
}
.width90{
  width: 90% !important;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Vazirmatn", sans-serif;
}
h1 {
  font-size: 48px !important;
}
h2 {
  font-size: 40px;
}
h4 {
  font-size: 20px;
  font-weight: 100 !important;
  color: #0c7dc2;
}
h5 {
  font-size: 24px;
}
p {
  font-size: 15px;
}
.p20 {
  font-size: 18px;
}
.mtp {
  margin-top: 70px;
}
.w260 {
  width: 260px !important;
}
.bg-gray {
  background: #f9f9f9;
}

.all-header{
	width: 100%;
	height: 106px;
	background: #021a33;
}
.bg-deep-gray {
  background: #fafafa;
  position: relative;
  padding-bottom: 80px;
  padding-top: 100px;
}
.bg-deep-gray::before {
  content: url(../src/assets/images/cornerleft.svg);
  position: absolute;
  top: 2px;
  left: 0px;
}
.bg-deep-gray::after {
  content: url(../src/assets/images/cornerright.svg);
  position: absolute;
  bottom: -4px;
  right: 0px;
}
.all-company-feature {
  width: 100%;
  float: left;
}
.banner-section {
  width: 100%;
  min-height: 520px;
  /* display: flex;
	align-items: flex-end; */
  position: relative;
  background: url(../src/assets/images/banner.png) no-repeat center center;
  background-size: cover;
  padding-bottom: 70px;
}
section.banner-section:before {
  width: 100%;
  height: 100%;
  /* display: flex;
	align-items: flex-end; */
  content: "";
  background: rgba(0, 0, 0, 0.6);
  /* background: linear-gradient(141.48deg, rgba(240, 240, 240, 0.5) 12.82%, rgba(145, 145, 145, 0.5) 16.52%, rgba(71, 70, 70, 0.5) 19.45%, rgba(15, 14, 14, 0.5) 22.72%, rgba(15, 14, 14, 0.5) 32.58%, rgba(15, 14, 14, 0.5) 63.99%, rgba(15, 13, 13, 0.5) 77.84%); */
  position: absolute;
  padding-bottom: 70px;
  top: 0px;
}
.btn-custom {
  background: linear-gradient(180deg, #33a1e4 0%, #0881cb 100%);
  border-radius: 40px !important;
  border: 0px !important;
  white-space: nowrap;
}
.btn-custom:hover {
  background: linear-gradient(180deg, #0881cb 0%, #33a1e4 100%);
}
/* baner css */
.slider-banner {
  width: 100%;
  float: left;
  margin-top: 180px;
}
.slider-banner h1 {
  font-size: 46px !important;
}
.slide-arrow .carousel-control-prev {
  display: none !important;
}
.slide-arrow .carousel-control-next {
  display: none !important;
}
.slider-banner.slide-arrow .carousel-indicators {
  right: -250px !important;
  position: absolute !important;
  bottom: inherit;
  left: inherit;
  top: 100px;
  transform: rotate(90deg);
}
.slider-banner.slide-arrow .carousel-indicators [data-bs-target] {
  height: 2px;
}
.btn-big {
  width: 230px;
  padding: 11px !important;
  font-size: 18px !important;
  position: relative;
}
.btn-big svg {
  position: absolute;
  right: 28px;
  top: 16px;
}
section.service-slider-block {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.block-item {
  width: 260px;
  background: rgba(74, 76, 94, 0.6);
  border: 1px solid #838383;
  border-radius: 8px;
  height: 90px;
  margin-right: 22px;
  margin-top: 152px;
}
.block-item span {
  width: 50px;
  height: 50px;
  left: 129px;
  /* top: 616px; */
  background: rgba(140, 143, 183, 0.4);
  float: left;
  color: #97d6fc;
  font-size: 23px;
  text-align: center;
  border-radius: 40px;
  padding: 5px;
  margin-top: 19px;
  margin-left: 12px;
}
.block-item p {
  margin: 0px;
  float: left;
  margin-top: 30px;
  font-size: 16px;
  margin-left: 10px;
}
.company-feature {
  background: #ffffff;
  box-shadow: 2px 0px 20px 8px rgb(185 197 205 / 21%);
  border-radius: 14px;
  padding: 30px 22px;
  min-height: 510px;
  margin-top: 40px;
}
.company-feature span {
  width: 78px;
  height: 78px;
  border: 1px dashed #178dd4;
  border-radius: 100px;
  margin: auto;
  display: table;
}
.company-feature span img {
  width: 62px;
  height: 52px;
  margin: auto;
  display: table;
  margin-top: 12px;
}
ul.ul-feature {
  padding: 0px;
  list-style: none;
  margin-top: 22px;
  width: 100%;
  float: left;
}
ul.ul-feature svg {
  float: left;
  width: 30px;
  margin-top: 3px;
  fill: #7ec9f7;
}
ul.ul-feature li {
  width: 100%;
  float: left; /* border: solid; */
}
ul.ul-feature li p {
  width: 89%;
  float: left;
  font-size: 16px;
  color: #000;
}
/* frame-bg */
.frame-bg {
  width: 100%;
  height: 100%;
  float: left;
  /* background: url(../src/assets/images/frame.svg) no-repeat center center; */
}
ul.ul-tech-list {
  display: inline-block;
  list-style: none;
  padding: 0px;
  margin: 0px;
  padding-top: 30px;
  width: 100%;
  background: #ffff;
}
ul.ul-tech-list li {
  width: 20%;
  float: left;
  border-right: 1px dashed #cecdcd;
  border-bottom: 1px dashed #cecdcd;
  display: inline-flex;
  height: 100px;
  text-align: center;
  padding-top: 24px;
  position: relative;
  padding-left: 5%;
}
.border-top-do {
  border-top: 1px dashed #cecdcd;
}
ul.ul-tech-list li:after {
  width: 30px;
  height: 30px;
  content: "";
  display: block;
  position: absolute;
  left: -15px;
  background: #ffffff;
  top: -17px;
  z-index: 99;
}
ul.ul-tech-list li span {
  width: 46px;
  height: 46px; /* border: solid; */
}
ul.ul-tech-list li span img {
  width: 100%;
}
ul.ul-tech-list li p {
  margin: 0px;
  margin-top: 9px;
  font-size: 16px;
  padding-left: 10px;
}
.schedu-bg {
  width: 100%;
  height: 550px;
  background: url(../src/assets/images/schdu.svg) no-repeat center center;
  position: relative;
}
.schedu-bg:before {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    #1087cf 0%,
    rgb(20 106 158 / 88%) 20.83%,
    rgb(20 87 127 / 68%) 66.15%,
    rgb(67 143 189 / 0%) 100%
  );
  content: "";
  display: block;
  position: absolute;
}
.block-schedue {
  position: relative;
  padding: 90px 52px;
}
.ul-tech-list a {
  color: #000;
}
.form-area {
  padding: 60px 47px !important;
}
.h3-schedule {
  font-weight: normal; padding-top: 30px;
}
.form-area .form-control {
  height: 44px;
}
.textform {
  min-height: 70px !important;
}
.company-slide {
  background: #ffffff;
  box-shadow: 2px 0px 20px 8px rgb(185 197 205 / 21%);
  border-radius: 14px;
  padding: 19px;
  min-height: 120px;
  float: left;
  width: 100%;
  /* padding-top: 100px; */
  border: dashed 1px #9fafb9;
}
.slider-icon {
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 100px;
  border: solid 7px #eaeaec;
  margin: auto;
  line-height: 61px;
  text-align: center;
  margin-top: 10px;
}
.slider-icon img {
  width: 54px;
}
.company-slide h5 {
  font-weight: 400;
  width: 100%;
  margin-top: 38px;
  margin-bottom: 2px;
  position: relative;
  padding-left: 7px;
}
.company-slide h5:before {
  width: 50px;
  height: 2px;
  background: #1086cf;
  content: "";
  position: absolute;
  top: -9px;
}
.slider-custom .carousel-indicators {
  display: none;
}
.slider-custom .carousel-control-prev {
  opacity: 1 !important;
  position: absolute;
  right: 93px !important;
  width: 60px !important;
  height: 36px !important;
  left: inherit !important;
  background: linear-gradient(180deg, #1685c8 0%, #0881cb 100%) !important;
  border-radius: 40px 0px 0px 40px;
  padding: 19px !important;
  top: -60px !important;
}
.slider-custom .carousel-control-prev:hover {
  background: linear-gradient(180deg, #115d8d 0%, #2393d7 100%) !important;
}
.slider-custom .carousel-control-next {
  opacity: 1 !important;
  position: absolute;
  right: 43px !important;
  width: 60px !important;
  height: 36px !important;
  background: linear-gradient(180deg, #2896d9 0%, #2393d7 100%) !important;
  border-radius: 0px 40px 40px 0px;
  padding: 19px !important;
  top: -60px !important;
}
.slider-custom .carousel-control-next:hover {
  background: linear-gradient(180deg, #094b74 0%, #1685c8 100%) !important;
}

.slider-custom .owl-nav button.owl-prev {
  opacity: 1 !important;
  position: absolute;
  right: 93px !important;
  width: 60px !important;
  height: 36px !important;
  left: inherit !important;
  background: linear-gradient(180deg, #1685c8 0%, #0881cb 100%) !important;
  border-radius: 40px 0px 0px 40px;
  padding: 19px !important;
  top: -60px !important;
  color: #fff !important;
  font-size: 26px !important;
}
.slider-custom .owl-nav button.owl-prev span {
  top: -4px;
  position: relative;
}
.slider-custom .owl-nav button.owl-prev:hover {
  background: linear-gradient(180deg, #115d8d 0%, #2393d7 100%) !important;
}
.slider-custom .owl-nav button.owl-next {
  opacity: 1 !important;
  position: absolute;
  right: 43px !important;
  width: 60px !important;
  height: 36px !important;
  background: linear-gradient(180deg, #2896d9 0%, #2393d7 100%) !important;
  border-radius: 0px 40px 40px 0px;
  padding: 19px !important;
  top: -60px !important;
  color: #fff !important;
  font-size: 26px !important;
}
.slider-custom .owl-nav button.owl-next span {
  top: -4px;
  position: relative;
}
.slider-custom .owl-nav button.owl-next:hover {
  background: linear-gradient(180deg, #094b74 0%, #1685c8 100%) !important;
}
.ul-list-about li {
  display: flex;
  font-weight: bold;
  /* margin-bottom: 0px !important; */
  width: 100%;
  float: left;
  line-height: 14px;
}
.ul-list-about li span {
  color: #2694d8 !important;
  margin-right: 7px;
  top: -2px;
  position: relative;
}
img.abouimg {
  width: 450px;
  margin: auto;
  display: table;
}
.slider-custom-two .owl-nav button.owl-prev {
  left: -650px !important;
  right: inherit !important;
  bottom: -120px !important;
  top: inherit !important;
}
.slider-custom-two .owl-nav button.owl-next {
  left: -590px !important;
  right: inherit !important;
  bottom: -120px !important;
  top: inherit !important;
}
.slider-custom-two .carousel-indicators {
  display: block !important;
  top: 171px;
  text-align: center;
}
.slider-custom-two .carousel-indicators [data-bs-target] {
  background-color: #2595d8;
}
.slider-custom-two .company-slide {
  padding: 26px 0px;
  min-height: 92px;
}
.slider-icon2 {
  width: 150px;
  margin: auto;
  display: table;
}
.slider-icon2 img {
  width: 150px;
}
img.mg-icon {
  height: 35px;
}
.slider-custom-two .carousel-item {
  margin-top: 52px;
}
.company-block-feature-two {
  border: 10px solid #d1e5f1;
  min-height: 600px;
  width: 500px;
  margin-top: 30px;
  position: relative;
  background: #fff;
}
.company-block-feature-two::after {
  content: url(../src/assets/images/cornerright.svg);
  position: absolute;
  bottom: -54px;
  right: -55px;
  z-index: -1;
}
.company-block-feature-two img {
  width: 100%;
}
.company-block-feature-two img {
  width: 100%;
  top: 180px;
  position: relative;
  right: 92px;
}
.company-block-feature-two:before {
  width: 19px;
  height: 310px;
  background: #ffffff;
  position: relative;
  content: "";
  display: block;
  position: absolute;
  left: -15px;
  top: 144px;
}
.position-img {
  float: right;
}
.position-img img {
  right: -92px;
}
.position-img:before {
  left: inherit;
  right: -15px;
}
.mission-vission {
  margin-top: 100px;
}
.mission-vission .slider-icon {
  margin-top: 20px;
}
.mission-vission h5 {
  margin-top: 15px;
  padding-left: 0px;
}
.mission-vission h5:before {
  display: none !important;
}
img.img-client {
  width: 90%;
}
.display-none-slide .carousel-control-prev {
  display: none;
}
.display-none-slide .carousel-control-next {
  display: none;
}
.client-block {
  background: #fafafa;
  border-radius: 40px 0px;
  min-height: 250px;
  padding: 40px;
}
.client-top {
  width: 100%;
}
img.client-av {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 100px;
  float: left;
}
.client-details {
  float: left;
  width: 76%;
  margin-left: 3%;
  margin-top: 10px;
}
.detail-message {
  width: 100%;
  float: left;
  padding-left: 15%;
}
.display-none-slide .carousel-indicators {
  top: 255px;
}
.block-list {
  background: #fff;
  border: solid 10px #fafafa;
  width: 100%;
}
.block-banner img {
  width: 100%;
  height: 260px;
  object-fit: cover;
}
.block-status {
  background: #5a7bdf;
  width: 90%;
  margin: auto;
  padding: 8px 12px;
  border-radius: 10px;
  color: #fff;
  margin-top: -24px;
  position: relative;
}
.block-status svg {
  margin-right: 7px;
  top: -1px;
  position: relative;
}
.width70 {
  width: 70% !important;
}
.width96 {
  width: 96% !important; float: right;
}
.bg-get-quote {
  background: #087bc1;
  padding: 50px 0px;
  padding-top: 74px;
  color: #fff;
}
img.get-quote {
  width: 680px;
  top: 1px;
  position: relative;
}
.border-white {
  border: solid 1px #000 !important;
}
.sub-banner {
  position: relative;
  padding-left: 60px;
}
.all-sub-banner {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  position: relative;
  background: #021a33 url(../src/assets/images/cecb.png) no-repeat left center;
}
.technology-list {
  color: #fff;
  border: 0px;
  padding: 6px 8px;
  font-size: 19px;
  background: linear-gradient(145deg, #052649, #053f7f);
  box-shadow: 10px 10px 19px #02162b, -10px -10px 19px #032449;
  margin-right: 20px;
  text-transform: uppercase;
  margin-top: 19px;
  border-radius: 100px;
  float: left;
  width: 100%;
}
.technology-list span {
  background: linear-gradient(145deg, #08294c, #073970);
  /* box-shadow: 10px 10px 19px #02162b, -10px -10px 19px #0c2845; */
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  text-align: center;
  color: #83c0e9;
  font-size: 24px;
  line-height: 43px;
}
.technology-list label {
  float: left;
  margin: 0px;
  width: 80%;
  margin: 12px;
  font-size: 17px;
}
.get-touch-form {
  width: 100%;
  border-top: solid 130px #021a33;
  background: #fff url(../src/assets/images/cecb2.png) no-repeat left center;
  display: flex;
  align-items: flex-end;
  height: 100vh;
  padding: 30px;
  /* top: 22%; */
  position: relative;
  padding-top: 0px;
}
.get-touch-form form {
  width: 92%;
  margin: auto;
}
.img-width {
  width: 90%;
  float: right;
}
.slider-icon.icon-all {
  width: 90px;
  height: 90px;
  background: #fff;
  border-radius: 100px;
  border: solid 7px #ebf6f6;
  font-size: 46px;
  color: #f28c36;
  margin-top: 72px;
}
.all-services .mission-vission {
  margin-top: 40px;
  min-height: 280px;
}
.mt-service {
  margin-top: 50px;
}
.work-block-slide {
  background-color: #fff;
  border-radius: 20px;
  border: dashed 1px #9fafb9;
  padding: 9px;
}
.img-width-full {
  width: 100%;
  border-radius: 20px;
  height: 250px;
  object-fit: cover;
}
.mb-150 {
  margin-bottom: 80px;
}
.industry-feature img {
  width: 100%;
}
.industry-feature {
  min-height: 544px;
}
.pos-re .form-control {
  border: 0px;
  border-bottom: dashed 1px #afaaaa;
  border-radius: 0px;
  resize: none;
  padding-left: 36px; background-color: transparent;
}
.pos-re {
  position: relative;
}
.pos-re span {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #c1bdbd;
  top: 4px;
  left: 16px;
}
.comspan {
  top: 24px;
}
.width-full {
  width: 96%;
  float: left;
}
.width-100 {
  width: 100% !important;

}
.com-miss-vis .mission-vission {
  min-height: 250px !important;
}
.com-miss-vis .slider-icon.icon-all {
  margin-top: 29px !important;
}
.get-touch-form .btn-custom {
  width: 80%;
}
.custom-industry .company-slide {
  background: #283357 url(../src/assets/images/industry-bg.png) left top no-repeat;
  border-radius: 20px;
  min-height: 250px;
  position: relative;
  border: 0px;
}

.custom-industry .company-slide h5 {
  position: absolute;
  bottom: 30px;
  color: #ffff;
  font-size: 22px;
  font-weight: bold;
}

.custom-industry .slider-icon {
  background: transparent;
  border: 0px;
  border-radius: 0px;
  float: right;
}
.bg-deep-gray2 {
  background: #fafafa !important;
}
.bg1 {
  background-color: #457b9d !important;
}

.bg2 {
  background-color: #3176a1 !important;
}
.bg3 {
  background-color: #174270 !important;
}
.custom-industry .company-slide h5:before {
  background: #fff;
}
.owl-dots {
  display: none;
}
.certification-slide .owl-nav {
  bottom: -38px !important;
  position: relative;
}
.slider-custom-two .owl-stage-outer {
  top: 106px;
}

.blog-all .company-slide {
    border: 0px; padding: 0px; position: relative;
}
.blog-all .slider-icon2 {
    width: 100%;
    height: 190px;
    display: table;
    border-radius: 12px;
}
.blog-all img{
	border-radius: 12px; height: 300px; object-fit: contain; width: 100%;
}
.blog-ul{
	display: flex;
    align-items: center;
    margin: 15px 0 20px; list-style: none; padding: 0px;
	 margin-top: 53px;

}
ul.blog-ul li svg {
    top: -2px;
    position: relative;
    margin-right: 7px;
}
.blog-ul li{
	color: #999;
    margin: 0 20px 0 0;
}
.blog-all .owl-dots {
    display: block;
    position: absolute;
    right: 260px;
    bottom: -140px;
}
.owl-theme .owl-dots .owl-dot span{
	background-color: #addaf7 !important;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
	background-color: #0a5d94 !important;
}
.blog-wr{
	position: relative;
}
.blog-wr::after {
    content: "";
    position: absolute;
    right: -135px;
    top: 5px;
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: solid #e2f2fc 20px;
}

.blog-all .company-slide:before {
    height: 90px;
    width: 90px;
    content: "";
    position: absolute;
    right: -15px;
    bottom: -10px;
    background: #487d9e;
    border-radius: 50px 50px 10px 50px;
    box-shadow: 5px 10px 20px rgb(73 126 159 / 30%); border: solid 10px #fff;
    z-index: 1;
}
.blog-all .company-slide::after {
    height: 45px;
    width: 45px;
    right: 7px;
    bottom: -20px;
    background: #fff;
    border-radius: 50px 50px 10px 50px;
}
.w190{
	width: 190px !important;
}
img.blog-details {
    width: 64%;
    border-radius: 20px;
    margin-top: 50px; position: relative; z-index: 5;
}
img.blog-details {
    width: 64%;
    border-radius: 20px;
    margin-top: 30px;
    height: 370px;
    object-fit: cover;
}
 .entry-meta ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.entry-meta ul li{
    position: relative;
    display: inline-block;
    color: #121521;
    margin-right: 21px; color: #999;
}
.entry-meta{
	width:64%; margin: auto;
}
h4.h4blog {
    font-size: 37px;
    color: #000;
}
.p-blog {
    line-height: 28px;
    font-size: 16px;
}
blockquote.block-d {
    width: 64%;
    margin: auto;
    /* text-align: left; */
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px!important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin-bottom: 20px;
    margin-top: 20px;
    font-style: italic;
}
blockquote.block-d h6 {
    font-size: 25px;
}
blockquote.block-d p {
    font-size: 16px;
    line-height: 27px;
    color: #767676;
}
blockquote.block-d:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #2342a1;
    margin-top: 20px;
    margin-bottom: 20px;
}
blockquote.block-d svg {
    /* padding: initial; */
    position: absolute;
    left: 60px;
    font-size: 50px;
    top: 20px;
    color: #e8e8e8;
}
.block-d{
	width: 64%;
    margin:40px auto;
}
h5.text-start span {
	width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 70% 30% 30% 70%/60% 40%;
    background-color: #2342a1;
    color: #fff;
    transition: .5s;
    display: inline-block;
    font-size: 11px;
    position: relative;
    left: -9px;
    top: -3px;
}

h4.h4project {
    color: #f9fdff;
    font-size: 20px;
    font-weight: bold;
}
h4.h4project svg {
    margin-right: 7px;
    top: -2px;
    position: relative;
}
.portfolio-banner {
    height: 520px;
    display: block;
}
.mat220{
	margin-top: 220px !important;
}
section.port-sec {
    width: 96%;
    background: #fff;
    margin: auto;
    margin-top: -103px;
    position: relative;
    min-height: 400px;
    border-radius: 30px;
	padding: 50px;
}
.port-sec .nav-tabs {
    border: 0px;
    width: 370px;
    margin: auto;

}

.port-sec .nav-tabs .nav-link {
    border-radius: 40px 40px 40px 10px;
    background: #deeff9;
    font-weight: 500;
    margin: 0 8px; border: 0px; color: #000;
}
.port-sec .nav-link.active {
    background-color: #4b80a1 !important; border: 0px; color: #fff !important;
}
.blog-tech {
    width: auto;
    float: left;
    border: 1px solid #ddd;
    padding: 7px 12px;
    display: flex;
	margin-right: 10px;
    margin-bottom: 12px; height: 48px;
}
.blog-tech img {
    width: 30px;
}
.blog-tech p {
    margin: 0px;
    margin-left: 10px;
    margin-top: 2px;
}
img.portimg {
    height: 450px;
}
.mt-173{
	margin-top: -299px !important;
}
.portfolio-com {
    width: 100%;
    height: 300px;
    overflow: hidden;
    margin-bottom: 22px;
}
.portfolio-com img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 12px;
}
.member-h2{
	color: rgb(5, 118, 170);
}
.zindex-all{
	position: relative; z-index:2;
}
.peopele-h5{
	position: relative;
    width: auto;
    display: table;
    margin: auto;
}
.peopele-h5:after {
    width: 60px;
    height: 2px;
    background: #0476aa;
    content: '';
    display: block;
    top: 10px;
    position: absolute;
    right: -70px;
}
.peopele-h5:before {
    width: 60px;
    height: 2px;
    background: #0476aa;
    content: '';
    display: block;
    top: 10px;
    position: absolute;
    left: -70px;
}
.team-member-block {
    width: 100%;
    height: 360px;
    background: #f7f7f9; text-align: center;
}
.team-member-block img.client-av {
    width: 190px;
    height: 190px;
    margin: auto;
    display: table;
    float: inherit;
    top: -82px;
    position: relative;
}
.team-member-block h3 {
    margin-top: -52px;
    font-size: 23px;
}
.team-member-block p {
    width: 100%;
    text-align: center;
    color: #736e6e;
}
.team-social {
    width: auto;
    margin: 50px auto;
    border-top: solid 1px #ddd;
    display: table;
    padding: 0px 53px;
}
.team-social button {
    width: 40px;
    height: 40px;
    background: #fff;
    display: inline-block;
}

.team-social button {
    width: 40px;
    height: 40px;
    background: #fff;
    display: inline-block;
    border-radius: 40px;
    margin-top: 12px;
    margin: 22px 6px;color: #503ac8;
}

.team-social a {
color: #503ac8;
  
}

.slide-img2 img {
  height: 50px !important;
  object-fit: contain !important;
}

.comapny-status{
  background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: #fff;
    padding-top: 45px;
    padding-bottom: 15px;
    border-radius: 8px;
    /* box-shadow: 0 0 30px #dededea6; */
    margin-top:110px;
    position: relative;
   /* background: url(../src/assets/images/partblock.png); */
}
.comapny-status img {
  width: 76px;
}
.comapny-status h3 {
  color: #0171bc;
  font-size: 31px;
  width: 100%;
  text-align: center;
  margin-top: 21px; margin-bottom: 4px;
}
.comapny-status p {
  font-size: 17px;
  /* font-weight: bold; */
  color: #000;
}


.com-miss-vis .mission-vission2{
  min-height: 206px !important; border: 0px;
}
.ullist-all p{
  line-height: 24px !important;
}

.ullist-all li span{
  top: 5px;
}
.ul-list-about li span.spanlist{
  
  width: 100%;
  float: left;
  font-weight: normal;
  color: #000 !important;
  margin-top: 7px;
  margin-bottom: 9px;
  line-height: 25px;
  font-size: 14px;
}


.company-feature {
  min-height:560px;

}
.bg-blackall{
  background: #021a33 url(../src/assets/images/cecb.png) no-repeat left center; padding-bottom: 140px;
}
.bg-blackall .company-feature{
  box-shadow: none;
}
.customHeader #basic-navbar-nav a:hover {
  color: #866cf3;
  background: transparent;
  color: #57aade !important;
}
.bg-blackall .company-feature {
  min-height: 390px;
}
.database-block .company-feature {
  min-height: 390px;
  border: 1px dashed #86a5b8;
}

.company-feature span svg {
  font-size: 40px;
  color: #795548;
  text-align: center;
  margin: auto;
  display: table;
  top: 19px;
  position: relative;
}
.company-status-block {
  width: 20%;
  float: left;
}
.benifit-block {
  background-color: #fff;
  box-shadow: 0 10px 50px rgb(211 217 234 / 40%);
  border-radius: 6px 40px 6px 40px;
  padding: 40px;
  position: relative; margin-top: 30px;min-height: 227px;
}
.benifit-block span {
  position: absolute;
  right: 10px;
    top: -30px;
}
.benifit-block span img {
  width: 46px;
}
.block-hello {
  position: relative;
  width: 100%;
  background: #deebe9;
  padding: 32px;
  border-radius: 30px;
  margin-top: 162px;
  /* padding-top: 0px; */
}
.block-hello:after {
  border-left: 20px solid #deebe9;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  content: '';
  position: absolute;
  right: -40px;
  top: 50%;
  bottom: 50%;
  margin: auto;
}
img.allflag {
  width: 100px;
  margin-bottom: 20px; float: left;
}
.head-ofc-area ul {
  float: left;
}
.head-ofc-area ul {
  float: left;
  margin-left: 26px;
  width: 74%;
}
.head-ofc-area ul h4 {
  color: #005183;
  margin-bottom: 0px;
  /* font-weight: bold; */
}
iframe.map {
  width: 100%;
  height: 494px;
  border: solid 10px #fff;
}
.head-ofc-area {
  border: 1px dashed #c2cbd1;
  padding: 20px; width: 100%; float: left;
  background: #fff;
  
}
.contact-bg{
  padding-bottom:130px;
}
.address {
  background: #ffffff;
  box-shadow: 2px 0px 20px 8px rgb(185 197 205 / 21%);
  border-radius: 14px;
  padding: 50px 22px;
  min-height: auto;
  margin-top: 40px;
  min-height: 338px;
}
.address img.allflag {
  width: 120px; height:80px;
  border-radius: 8px;
  border: dashed 1px #009688;
  padding: 5px; object-fit: cover;
}
.bg-blackall{
  background: #053f7f;
}

.border-form .get-touch-form {
  height: auto; background-color: transparent;
}
.bg-form h3 {
  color: #fff;
}
.border-form  .form-control {
  border-bottom: dashed 1px #ffffff; color: #fff;

}
.border-form .pos-re span {
  color: #ffffff;

}
.border-form .form-control::-webkit-input-placeholder { /* Edge */
  color: #fff;
}

.border-form .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
}

.border-form .form-control::placeholder {
  color: #fff;
}
.form-control:focus{
  background-color: transparent !important;
}
.bg-form {
  background: linear-gradient(180deg, #235878 0%, #033d60 100%);
  position: relative;
}
a.activenone.active:after{
  background: transparent !important;
}
.dis-hide-show .btn-primary {
  background: #000000;
  border: dashed 1px #2084c3 !important;
  width: 143px;
}
.left-contact {
  box-shadow: 0px 0px 20px rgba(0,0,0,.1);
  position: relative;
}
.left-contact:before {
  position: absolute;
  content: "";
  left: -30px;
  top: 30px;
  height: 100%;
  width: 100%;
  border: 10px solid #7a8b95;
  z-index: -1;
}
.bg-form:after {
  border-left: 30px solid transparent;
  border-right: 30px solid #ffffff;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  content: '';
  position: absolute;
  right: 0px;
  top: 50%;
  bottom: 50%;
  margin: auto;
}
.get-touch-form.dis-pro {
  border: 0px;
 
  height: auto;
  display: block;
}
.box-shadow-none{
  box-shadow: none;
}
.hire-ul li p {
  font-weight: normal;
  line-height: 25px;
  top: -5px;
  position: relative;
}
.mis-vis .slider-icon {
  width: 150px;
  height: 150px;border: solid 7px #f6f6fb;
  padding: 0px;
  margin-top: 5px;
}
.mis-vis .slider-icon img {
  width: 100%;
  height: 138px;
  border-radius: 100px;
}
/* =============responsive css================== */

@media (min-width: 991px) and (max-width: 1325px) {
  .technology-list label {
    width: 68%;
  }
}
@media (min-width: 991px) and (max-width: 1199px) {
  .container {
    max-width: 1130px !important;
  }
  .customHeader #basic-navbar-nav a {
    padding: 2px 11px !important;
    font-size: 15px !important;
  }
  h2.navLogo {
    width: 240px;
  }
}
@media (min-width: 200px) and (max-width: 1150px) {
  ul.ul-feature li p {
    font-size: 14px;
  }
  ul.ul-feature li p {
    margin-bottom: 6px;
  }
  ul.ul-feature svg {
    font-size: 14px !important;
  }
  .company-feature {
    padding: 30px 9px;
  }
  .company-block-feature-two {
    width: 100%;
  }
  .position-img img {
    right: 0px;
  }
  .company-block-feature-two img {
    right: 0px;
  }
  .company-block-feature-two:before {
    display: none;
  }
  .company-block-feature-two:after {
    display: none;
  }
}
@media (min-width: 200px) and (max-width: 991px) {
  .navbar-toggler {
    position: absolute;
    right: 150px;
    top: 39px;
    box-shadow: none;
  }
  .navbar-toggler-icon {
    background-image: url(../src/assets/images/menu.svg) !important;
    background: no-repeat;
  }
  .dis-noneall {
    display: none;
  }
  .nav-grid-cutom {
    /* flex: 0 0 auto; */
    width: 100%;
  }
  .custom-navbar {
    background: #000;
    padding: 12px;
  }
  .nav-grid-cutom {
    width: 100% !important;
    margin-top: 0px !important;
  }
  .custom-navbar .active:after {
    display: none !important;
  }
  .customHeader #basic-navbar-nav a {
    font-size: 15px;
  }
  .drop-down-nav .dropdown-menu h4 {
    margin-top: 20px;
    margin-bottom: 2px !important;
  }
  h2.navLogo {
    width: 180px !important;
    margin-top: 10px;
  }
  .navButton .btn-custom {
    position: absolute;
    top: 38px;
    left: 0px;
    right: 0px;
    margin: auto;
    display: table;
    width: 150px;
    padding: 6px 0px;
  }
  .container {
    padding-right: 0px !important;
    --bs-gutter-x: 2.9rem !important;
  }
  .all-sub-banner {
    height: auto;
    display: block;
    align-items: inherit;
    padding-bottom: 50px;
  }
  .sub-banner {
    padding-left: 0px;
  }
  .technology-list label {
    width: 74%;
  }
}
@media (min-width: 681px) and (max-width: 991px) {
  .block-item span {
    margin: 10px auto !important;
    display: table !important;
    float: inherit;
  }
}
@media (min-width: 600px) and (max-width: 991px) {
  .block-item p {
    margin: 0px;
    float: left;
    margin-top: 1px;
    font-size: 15px;
    line-height: 18px;
    margin-left: 10px;
  }
  .block-item {
    width: 260px;
    height: 120px;
  }
}
@media (min-width: 200px) and (max-width: 930px) {
  ul.ul-tech-list li span {
    width: 28px;
    height: 28px;
    margin-top: 10px; /* border: solid; */
  }
  ul.ul-tech-list li p {
    margin: 0px;
    margin-top: 12px;
    font-size: 15px;
    padding-left: 3px;
  }
}
@media (min-width: 200px) and (max-width: 767px) {
  .comapny-status img {
    width: 50px;
}
.head-ofc-area ul {
  float: left;
  margin-left: 0px;
  width: 100%;
}
.left-contact:before{
  border: 0px;
}
.comapny-status h3 {
  font-size: 25px;
  margin-top: 11px;
}
.comapny-status p {
  font-size: 11px;
  font-weight: bold;
  color: #000;
  width: 60px;
  margin: auto;
}
  h1 {
    font-size: 27px !important;
  }
  img.blog-details {
    width: 100%;
  }
  .entry-meta {
    width: 100%;
  }
  blockquote.block-d {
    width: 100%;
  }
  section.port-sec {
    padding: 10px;
}
.mt-173 {
  margin-top: -59px !important;

}
.abt-block .ps-0.col-md-9 {
  padding-left: 12px !important;
}
.peopele-h5:before{
  display: none;
}
.peopele-h5:after{
  display: none;
}
.team-member-block {
  margin-bottom: 120px;height: 280px;
}
.team-member-block img.client-av {
  width: 130px;
  height: 130px;
}
  .block-d{
    width: 100%;
  }
  .rowspace {
    margin-left: 0px !important;
  }
  .dis-none {
    display: none;
  }
  .dis-hide-show {
    display: block !important;
    position: initial !important;
    float: left;
  }
  .company-block-feature-two {
    min-height: auto;
    border: 2px solid #d1e5f1;
  }
  .company-block-feature-two img {
    top: 0px;
  }
  .block-crou .owl-dots {
    display: none !important;
}
  .pb-res{
    padding-bottom: 0px !important;
  }
  .dis-hide-show .btn-custom {
    position: initial !important;
    float: left;
  }
  .navbar-toggler {
    right: 10px;
  }
  .spaceres {
    left: 11px;
    position: relative;
  }
  .p20 {
    font-size: 15px;
    line-height: 20px;
  }
  .btn-big {
    width: 220px;
    padding: 11px !important;
    font-size: 17px !important;
  }
  .banner-section {
    height: auto;
    display: block;
    align-items: inherit;
  }
  .container {
    max-width: 95%;
  }
  .company-feature {
    min-height: auto;
    float: left;
  }
  .sch-block .ps-0 {
    padding: 0px;
    margin-left: 13px;
    position: relative;
    /* padding-right: 0px !important; */
    margin-right: 9px;
  }
  .company-slide {
    margin-bottom: 10px;
  }
  .form-area {
    padding: 60px 0px !important;
  }
  img.img-client {
    display: none;
  }
  .client-block {
    min-height: auto;
    float: left;
  }
  .detail-message {
    padding-left: 0%;
    margin-top: 12px;
  }
  .display-none-slide .carousel-indicators {
    top: 315px;
  }
  .block-list p {
    width: 100%;
    float: left;
  }
  .block-list {
    margin-bottom: 20px;
  }
  .bg-get-quote .btn-custom {
    float: left;
  }
  .footer-bottom ul {
    direction: ltr !important;
    margin-top: 0px !important;
  }
  .footer-bottom ul li {
    padding-left: 0px !important;
    padding-right: 15px;
  }
  img.get-quote {
    width: 500px;
  }
  .slider-custom-two .owl-nav button.owl-prev {
    top: inherit !important;
    bottom: 0px !important;
    right: 17px !important;
    position: relative;
    left: inherit !important;
  }
  .slider-custom-two .owl-nav button.owl-next {
    right: 23px !important;
    bottom: 0px !important;
    top: inherit !important;
    left: inherit !important;
  }
  .slider-custom-two .owl-stage-outer {
    top: 0px;
  }
  .slider-custom-two .owl-nav {
    width: 200px;
    margin: auto;
  }
.mt-5.mb-4.pb-5.pb-res.col-md-6 {
padding-bottom: 0px !important;
}
.comapny-status {
  margin-right:4%;
}
.comapny-status p {
  margin-bottom: 50px;
}
}
@media (min-width: 200px) and (max-width: 680px) {
  section.service-slider-block {
    display: block !important;
  }
  .block-item {
    margin-top: 21px;
    height: 60px;
  }
  .block-item span {
    width: 40px;
    height: 40px;
    font-size: 18px;
    margin-top: 9px;
    margin-left: 9px;
  }
  .block-item p {
    margin-top: 16px;
    font-size: 15px;
  }
  .slider-banner {
    margin-top: 150px;
  }
  ul.ul-feature li p {
    width: 85%;
  }
  ul.ul-tech-list li {
    width: 47%;
    border: dashed 1px #ddd !important;
    margin: 1%;
  }
  ul.ul-tech-list li:after {
    display: none;
  }
  ul.ul-tech-list li span {
    width: 42px;
    height: 42px;
  }
  ul.ul-tech-list li p {
    font-size: 14px;
    padding-left: 2px;
  }
  .container {
    max-width: 100%;
  }
  .bg-gray {
    left: 1%;
    position: relative;
  }
  img.get-quote {
    width: 320px;
  }
  .slider-custom .carousel-control-prev {
    top: inherit !important;
    bottom: -60px;
  }
  .slider-custom .carousel-control-next {
    top: inherit !important;
    bottom: -60px;
  }

  .slider-custom .owl-nav button.owl-next {
    top: inherit !important;
    bottom: -60px;
  }
  .slider-custom .owl-nav button.owl-prev {
    top: inherit !important;
    bottom: -60px;
  }

  img.abouimg {
    width: 320px;
  }
  .owl-theme .owl-nav {
    position: relative;
    width: 200px;
    margin: auto;
    display: table;
    /* border: solid; */
    top: -2px;
  }
  .slider-icon2 img {
    width: 90% !important;
    margin: auto;
  }
  .slider-icon2 {
    width: 100%;
  }
  .drop-down-nav .dropdown-menu {
    height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
}
}
@media (min-width: 200px) and (max-width: 532px) {
  .display-none-slide .carousel-indicators {
    top: inherit;
  }
}
@media (min-width: 200px) and (max-width: 450px) {
  ul.ul-tech-list li {
    height: 60px;
    padding-top: 2px;
  }
  ul.ul-tech-list li span {
    width: 34px;
    height: 34px;
  }
  ul.ul-tech-list li p {
    margin-top: 16px;
  }
  .comapny-status img {
    width: 44px;
}
.comapny-status h3 {
  font-size: 20px;
  margin-top: 13px;
}
.comapny-status p {
  font-size: 9px;
  font-weight: bold;
  color: #000;
  width: 55px;
  margin: auto;
}
}
